import React, { useEffect, useState } from "react";

import LoaderMaster from "../Loaders/Loaders";

import { postApiPrivate } from "../../api";

import { Card, Container } from "@mui/material";


import "../Profile/z_profile.css";

const buttonClass = `cursor-pointer text-white bg-black font-medium rounded-lg px-4 py-2 text-center`

const AdminCustomersDetails = (props) => {

  const { TRANSLATE, history, match, location } = props;

  const [isLoading, setLoading] = useState(true);
  const [deleteReviewLoading, setDeleteReviewLoading] = useState(false);

  const [review, setReview] = useState({});

  const handleDeleteReview = async () => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setDeleteReviewLoading(true);

    try {

      const FORM = { id: review.objectId };

      console.log("DELETE ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/reviews/delete', FORM, JWT);

      if (DATA_RESPONSE.error) {

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("DELETE ===> ", DATA_RESPONSE);

      setDeleteReviewLoading(false);

      alert("Review Deleted, redirecting to reviews page");

      window.location.replace('/admin/reviews');
    }
    catch (e) {

      setDeleteReviewLoading(false);
    }
  }

  useEffect(() => {

    const review_id = match.params.id || '';

    onFetchPublic(review_id);

  }, []);

  const onFetchPublic = async (user_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setLoading(true);

    try {

      const FORM = { id: user_id };

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/reviews/details', FORM, JWT);

      if (DATA_RESPONSE.error) {

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      //alert("DARA ===> "+JSON.stringify(DATA_RESPONSE, null, 3));

      setReview(DATA_RESPONSE.data || {});
      setLoading(false);

    }
    catch (e) {

      alert("ERROR ===> " + e);

      setLoading(false);
    }

  }

  if (isLoading) {

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_loading} />
    )

  }

  return (

    <Container maxWidth="lg" background={'#f0f5f9'}>
      <div className="flex flex-col w-full">
        <Card variant="outlined" style={{ marginBottom: '20px', padding: '20px' }}>
          <h2>Review Details</h2>
          <p><strong>Review Type:</strong> {review?.type}</p>
          {
            review?.text && review.text.length > 0 && (
              <p><strong>Reviewer Content:</strong> {review?.text}</p>
            )
          }
          <div className="flex flex-col gap-1">

            <h2 className="mt-4">Ratings</h2>

            {review?.rank && (
              <p>
                <strong>Rank:</strong> {parseFloat(review.rank).toFixed(1)}
              </p>
            )}
            {review?.communication && (
              <p>
                <strong>Communication:</strong> {parseFloat(review.communication).toFixed(1)}
              </p>
            )}
            {review?.punctuation && (
              <p>
                <strong>Punctuation:</strong> {parseFloat(review.punctuation).toFixed(1)}
              </p>
            )}
            {review?.accuracy && (
              <p>
                <strong>Accuracy:</strong> {parseFloat(review.accuracy).toFixed(1)}
              </p>
            )}
            {review?.cleanliness && (
              <p>
                <strong>Cleanliness:</strong> {parseFloat(review.cleanliness).toFixed(1)}
              </p>
            )}
            {review?.condition && (
              <p>
                <strong>Condition:</strong> {parseFloat(review.condition).toFixed(1)}
              </p>
            )}
            {review?.logistic && (
              <p>
                <strong>Logistic:</strong> {parseFloat(review.logistic).toFixed(1)}
              </p>
            )}
            {review?.products && (
              <p>
                <strong>Products:</strong> {parseFloat(review.products).toFixed(1)}
              </p>
            )}
            {review?.use && (
              <p>
                <strong>Use:</strong> {parseFloat(review.use).toFixed(1)}
              </p>
            )}
          </div>
        </Card>
        <Card variant="outlined" style={{ marginBottom: '20px', padding: '20px' }} className="flex flex-row justify-between gap-2">
          <Card variant="outlined" style={{ padding: '20px' }} className="w-full">
            <div className="flex flex-col gap-1">
              <a className={`${buttonClass}`} href={`/admin/stores/profile/${review?.store?.objectId}`} target="_blank">
                Store
              </a>
              <p><strong>Store ID:</strong> {review?.store?.objectId}</p>
              <p><strong>Store Name:</strong> {review?.store?.name}</p>
            </div>
          </Card>
          <Card variant="outlined" style={{ padding: '20px' }} className="w-full">
            <div className="flex flex-col gap-1">
              <a className={`${buttonClass}`} href={`/admin/users/profile/${review?.customer?.objectId}`} target="_blank">
                Customer
              </a>
              <p><strong>Customer ID:</strong> {review?.customer?.objectId}</p>
              <p><strong>Customer Name:</strong> {review?.customer?.full_name}</p>

            </div>
          </Card>
          {
            review?.order?.objectId && (
              <Card variant="outlined" style={{ padding: '20px' }} className="w-full">
                <div className="flex flex-col gap-1">
                  <a className={`${buttonClass}`} href={`/admin/orders/profile/${review?.order?.objectId}`} target="_blank">
                    Order
                  </a>
                  <p><strong>Order ID:</strong> {review?.order?.objectId}</p>
                </div>
              </Card>
            )
          }
          <button className="cursor-pointer text-white bg-black font-medium rounded-lg px-4 py-2 text-center" disabled={deleteReviewLoading} onClick={() => handleDeleteReview()}>
            {
              deleteReviewLoading ? "Deleting..." : "Delete Review"
            }
          </button>
        </Card>

      </div>
    </Container>
  )
}

export default AdminCustomersDetails

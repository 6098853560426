import React, { Component, useEffect, useState } from 'react';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';

import draftToHtml from 'draftjs-to-html';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactCountryFlag from 'react-country-flag';
import LoaderMaster from '../Loaders/Loaders';
import { ArrowBack } from 'react-ionicons';
import swal from 'sweetalert';
import {postApiPrivate} from '../../api';
import { GetRouteSearchParams } from './AdminHelper';

const Editor = typeof window === 'object' && require('react-draft-wysiwyg').Editor;
const htmlToDraft = typeof window === 'object' && require('html-to-draftjs').default;

const AdminLegal = (props) => {

  const {location, match, history} = props;

  const [TYPE, setType] = useState(null);
  const [LANG, setLang] = useState(null);

  useEffect(()=> {

    const matchId = match.params.id;
    const routeParams =  GetRouteSearchParams(location.search)
    const routeLang = routeParams.lang;

    if(matchId === 'MRpWrxSRDm'){
      //Terms
      setType('Terms & Conditions')
    }
    else if(matchId === 'BsCZJvyVj6'){
      //Privacy
      setType('Privacy Policy')
    }
    else if(matchId === 'jPPbyN4076'){
      //Cancellation
      setType('Cancellation Terms')
    }
    else if(matchId === 'KF2hAPmQM7'){
      //Cancellation
      setType('New Terms & Conditions')
    }

    const LangArray = ['en', 'it', 'es', 'fr', 'de'];

    if(LangArray.includes(routeLang)){
      setLang(routeLang)
    }

  }, [location.search, match.params.id])
  
  if(!TYPE){

    return (
      <div style={{paddingTop: 50, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <h1>Admin Legal 🧑🏼‍⚖️</h1>
        <p className='mb-8'>Choose one of the following Categories</p>

        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
          <button className='btn' onClick={()=> history.push('/admin/legal/profile/MRpWrxSRDm')} style={{marginRight: 5}}>Terms & Conditions</button>
          <button className='btn' onClick={()=> history.push('/admin/legal/profile/BsCZJvyVj6')} style={{marginRight: 5}}>Privacy Policy</button>
          <button className='btn' onClick={()=> history.push('/admin/legal/profile/jPPbyN4076')} style={{marginRight: 5}}>Cancellation Terms</button>
          <button className='btn' onClick={()=> history.push('/admin/legal/profile/KF2hAPmQM7')} style={{marginRight: 5}}>New Terms & Conditions</button>
        </div>
      
      </div>
    )

  }

  if(!LANG){

    return (
      <div style={{paddingTop: 50, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <h1>{TYPE} 🧑🏼‍⚖️</h1>
        <p className='mb-8'>Choose the language you want</p>

        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
          <button className='btn' onClick={()=> history.push('?lang=en')} style={{marginRight: 5}}>English <ReactCountryFlag countryCode={'gb'} /></button>
          <button className='btn' onClick={()=> history.push('?lang=it')} style={{marginRight: 5}}>Italiano <ReactCountryFlag countryCode={'it'} /></button>
          <button className='btn' onClick={()=> history.push('?lang=fr')} style={{marginRight: 5}}>French <ReactCountryFlag countryCode={'fr'} /></button>
          <button className='btn' onClick={()=> history.push('?lang=es')} style={{marginRight: 5}}>Spanish <ReactCountryFlag countryCode={'es'} /></button>
          <button className='btn' onClick={()=> history.push('?lang=de')} style={{marginRight: 5}}>German <ReactCountryFlag countryCode={'de'} /></button>
          {/* <button className='btn' onClick={()=> setLang('tr')} style={{marginRight: 5}}>Turkish <ReactCountryFlag countryCode={'tr'} /></button> */}
        </div>
      
      </div>
    )

  }


  return(
      <div style={{paddingTop: 50, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', background: '#f0f5f9'}}>
        <h1>{TYPE}</h1>
        <p className='mb-8'>Edit and Save the one you want</p>

          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
              <button className='btn' onClick={() => history.push('?lang=en')}
                      style={{marginRight: 5}}>English <ReactCountryFlag countryCode={'gb'}/></button>
              <button className='btn' onClick={() => history.push('?lang=it')}
                      style={{marginRight: 5}}>Italiano <ReactCountryFlag countryCode={'it'}/></button>
              <button className='btn' onClick={() => history.push('?lang=fr')}
                      style={{marginRight: 5}}>French <ReactCountryFlag countryCode={'fr'}/></button>
              <button className='btn' onClick={() => history.push('?lang=es')}
                      style={{marginRight: 5}}>Spanish <ReactCountryFlag countryCode={'es'}/></button>
              <button className='btn' onClick={() => history.push('?lang=de')}
                      style={{marginRight: 5}}>German <ReactCountryFlag countryCode={'de'}/></button>

              {/* <button className='btn' onClick={()=> setLang('tr')} style={{marginRight: 5}}>Turkish <ReactCountryFlag countryCode={'tr'} /></button> */}
          </div>

          <MasterEditor LANG={LANG} TYPE={TYPE} setLang={setLang} setType={setType}/>

      </div>
  )

}


const MasterEditor = (props) => {

    const {LANG, TYPE, setLang, setType} = props;

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);

  useEffect(()=> {

    onFetchLegal();

  }, [LANG, TYPE]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
  }

  const onFetchLegal = async() => {

      setLoading(true);

      try{

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/admin/legal/get', {lang: LANG, type: TYPE} , JWT);
            
            if(DATA_RESPONSE.error){
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return 
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);
            const CURRENT_STATE = DATA_RESPONSE.state || {};
            const CURRENT_CONTENT_HTML = CURRENT_STATE.__html || {}

            const blocksFromHtml = htmlToDraft(CURRENT_CONTENT_HTML);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);

            //alert("RESPONSE ===> "+JSON.stringify(CURRENT_CONTENT_HTML));

            setEditorState(editorState);
            setLoading(false);
            
        }
        catch(e){
            
            console.log("CREATING ===> ", e);

            setEditorState(EditorState.createEmpty());
            setLoading(false);
        }
  }

  const onSaveLegal = async() => {

      const CURRENT_LEGAL = {
        __html: draftToHtml(
                  convertToRaw(
                    editorState.getCurrentContent()
                  )
                )
      }
    
      setSaving(true);

      try{

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/admin/legal/post', {lang: LANG, type: TYPE, state: CURRENT_LEGAL} , JWT);
            
            if(DATA_RESPONSE.error){
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setSaving(false);
                return 
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setSaving(false);

            swal('Changes Saved', 'This following legal term has been saved successfully: \n\n'+ TYPE, 'success');
            
        }
        catch(e){
            
            console.log("CREATING ===> ", e);
            setSaving(false);
        }
  }

  const onResetLegal = () => {
    setLang(null);
    setType(null);
  }

  if(isLoading){
    return <LoaderMaster type="secondary" title={'Loading '+TYPE+'...'} />
  }



  const BarStyles = {
    height: '100px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
    background: '#21283F',
    bottom: '0'
  }


  const CURRENT_HTML = {
    __html: draftToHtml(
              convertToRaw(
                editorState.getCurrentContent()
              )
            )
  }
  
 
  return (
    <div style={{background: '#fff', marginTop: 50, minHeight: 400, padding: 20, maxWidth: 1200}}>

      <div style={BarStyles}>
        <div style={{display: 'flex', alignItems: 'center', background: 'black', color: '#fff', cursor: 'pointer', border: 'solid 3px #fff', borderRadius: 10, paddingLeft: 20, paddingRight: 20}} onClick={()=> onResetLegal()}>
          <ArrowBack color={"#fff"} style={{marginRight: 10}}/>  Back to Legal Menu
        </div>

        <div style={{display: 'flex', alignItems: 'center'}}>
          <img src='/assets/integrations/clickai_logo.png' style={{height: 40}}/>
        </div>
        {isSaving ? (
            <div style={{display: 'flex', alignItems: 'center', background: '#f1f1f1', color: '#000', cursor: 'not-allowed',  border: 'solid 3px #fff', borderRadius: 10, paddingLeft: 20, paddingRight: 20}}>
              Saving Changes...
            </div>
        ) : (
            <div style={{display: 'flex', alignItems: 'center', background: 'green', color: '#fff', cursor: 'pointer', border: 'solid 3px #fff', borderRadius: 10, paddingLeft: 20, paddingRight: 20}} onClick={()=> onSaveLegal()}>
              Save Changes
            </div>
        )}
       
      </div>

      {Editor && (

        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={onEditorStateChange}
          style={{background: '#fff'}}
        />
        
      )}
      

      {/* <textarea
        disabled
        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        style={{width: '100%', height: 200, marginTop: 200}}
      />

      <div
          dangerouslySetInnerHTML={CURRENT_HTML}
      /> */}

      

    </div>
  );

}

 

export default AdminLegal

import React, {useEffect, useState} from 'react';

import {postApiPrivate} from '../../api';

import {Chip, CircularProgress, Typography} from '@mui/material';

import MUIDataTable from 'mui-datatables';

import moment from 'moment/min/moment-with-locales';
import swal from 'sweetalert';

import {GetRouteSearchParams} from './AdminHelper';

import {EyeSharp} from 'react-ionicons'

const AdminLegals = (props) => {

    const { TRANSLATE, history, match, location, USER } = props;

    const [isLoading, setLoading] = useState(true);

    const [searchText, setSearchText] = useState('');

    const [PAGE, setPage] = useState(0);

    const [LEGALS, setLegals] = useState([]);
    const [TOTAL, setTotal] = useState(null);

    const LIMIT = 20;
    let CURRENT = LIMIT * (PAGE + 1);

    if (TOTAL < CURRENT) {
        CURRENT = TOTAL;
    }

    useEffect(() => {
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        if (!JWT) {
            return history.replace('/login');
        }
        else {

            var routeParams = GetRouteSearchParams(location.search);

            var page_id = match.params.page || 0;

            var sort_id = routeParams.sort || null;
            var direction_id = routeParams.dir || null;

            var current_page = parseInt(page_id);

            if (USER.admin) {
                onFetchLegals({
                    page: current_page,
                    sort: sort_id,
                    direction: direction_id
                });
            }
            else {
                setLoading(false)
                swal('You\'re not Authorized', 'You are not authorized to be here', 'error')

                history.push('/logout')
            }

        }

        window.scrollTo(0, 0);

    }, [match.params.page, location.search]);

    const onFetchLegals = async ({ page, sort, direction }) => {

        setPage(page);

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        setLoading(true);

        try {

            var FORM = {
                p: page,
                s: sort,
                d: direction,
                l: LIMIT
            }

            var DATA_RESPONSE = await postApiPrivate('/admin/legal', FORM, JWT);


            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setLegals(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

            window.scrollTo(0, 0)


        }
        catch (e) {

            console.log("CREATING ===> ", e);
            setLoading(false);
        }

    }

    const onSearchLegals = async (query) => {

        setSearchText(query);

        if (!query || query.length == 0) {

            var page_number = match.params.page || 0;
            var current_page = parseInt(page_number);
            onFetchLegals({
                page: current_page
            });

            return
        }

        if (query.length <= 3) {

            return
        }


        try {

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/admin/legal/search', { query: query }, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setLegals(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

        }
        catch (e) {

            console.log("CREATING ===> ", e);

            setTotal(0);
            setLegals([]);
            setLoading(false);
        }


    }

    const onSortLegals = (sort) => {

        if (sort === 'id') {
            return 'objectId'
        }
        else if (sort === 'name') {
            return 'search_name'
        }
        else if (sort === 'img') {
            return 'photo'
        }
        else if (sort === 'lang') {
            return 'language'
        }
        else if (sort === 'isCompleted') {
            return 'setup'
        }
        else {
            return sort
        }
    }

    const onChangePageLegals = ({ page, sort, direction }) => {
        let new_url = '/admin/legal/' + page;

        if (sort) {
            new_url = new_url + '?sort=' + sort + '&dir=' + direction;
        }

        history.push(new_url);
    }

    const onSelectLegal = (legal_id) => {
        history.push('/admin/legal/profile/' + legal_id);
    }


    //Table Data
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (id) => {

                    return (
                        <Typography variant='span' fontWeight={'bold'} onClick={() => onSelectLegal(id)} style={{ cursor: 'pointer' }}>
                            #{id}
                        </Typography>
                    )
                }
            },
        },
        {
            name: "type",
            label: "CODE",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const legalObx = LEGALS[rowIndex] || {};

                    const legal_id = legalObx.id;
                    const legal_code = legalObx.type || '';

                    return (
                        <div style={{ maxWidth: 200 }}>
                            <Chip
                                label={legal_code.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'uppercase' }}
                                onClick={() => onSelectLegal(legal_id)}
                            />
                        </div>

                    )

                }
            },

        },
        {
            name: "en",
            label: "ENGLISH 🇬🇧",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const legalObx = LEGALS[rowIndex] || {};
                    const legal_id = legalObx.id;

                    if (label === 'ON') {
                        return (
                            <Chip label={'ON'}
                                size='medium'
                                color='success'
                                avatar={<EyeSharp color={'#fff'} />}
                                onClick={() => history.push('/admin/legal/profile/' + legal_id + '?lang=en')}
                            />
                        )
                    }

                    return (
                        <Chip label={'OFF'} size='medium' color='error' />
                    )

                }
            },
        },
        {
            name: "it",
            label: "ITALIANO 🇮🇹",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const legalObx = LEGALS[rowIndex] || {};
                    const legal_id = legalObx.id;

                    if (label === 'ON') {
                        return (
                            <Chip label={'ON'}
                                size='medium'
                                color='success'
                                avatar={<EyeSharp color={'#fff'} />}
                                onClick={() => history.push('/admin/legal/profile/' + legal_id + '?lang=it')}
                            />
                        )
                    }

                    return (
                        <Chip label={'OFF'} size='medium' color='error' />
                    )

                }
            },
        },
        {
            name: "fr",
            label: "FRANÇAIS 🇫🇷",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const legalObx = LEGALS[rowIndex] || {};
                    const legal_id = legalObx.id;

                    if (label === 'ON') {
                        return (
                            <Chip label={'ON'}
                                size='medium'
                                color='success'
                                avatar={<EyeSharp color={'#fff'} />}
                                onClick={() => history.push('/admin/legal/profile/' + legal_id + '?lang=fr')}
                            />
                        )
                    }

                    return (
                        <Chip label={'OFF'} size='medium' color='error' />
                    )

                }
            },
        },
        {
            name: "es",
            label: "ESPANOL 🇪🇸",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const legalObx = LEGALS[rowIndex] || {};
                    const legal_id = legalObx.id;

                    if (label === 'ON') {
                        return (
                            <Chip label={'ON'}
                                size='medium'
                                color='success'
                                avatar={<EyeSharp color={'#fff'} />}
                                onClick={() => history.push('/admin/legal/profile/' + legal_id + '?lang=es')}
                            />
                        )
                    }

                    return (
                        <Chip label={'OFF'} size='medium' color='error' />
                    )

                }
            },
        },
        {
            name: "de",
            label: "GERMAN 🇩🇪",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const legalObx = LEGALS[rowIndex] || {};
                    const legal_id = legalObx.id;

                    if (label === 'ON') {
                        return (
                            <Chip label={'ON'}
                                  size='medium'
                                  color='success'
                                  avatar={<EyeSharp color={'#fff'} />}
                                  onClick={() => history.push('/admin/legal/profile/' + legal_id + '?lang=de')}
                            />
                        )
                    }

                    return (
                        <Chip label={'OFF'} size='medium' color='error' />
                    )

                }
            },
        },
        {
            name: "updatedAt",
            label: "UPDATED",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },
        {
            name: "createdAt",
            label: "CREATED",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },

    ];

    const data = LEGALS.map((item) => {

        moment.locale(TRANSLATE.lang);

        return {
            id: item.id,
            type: item.type,
            en: item.en ? 'ON' : 'OFF',
            it: item.it ? 'ON' : 'OFF',
            fr: item.fr ? 'ON' : 'OFF',
            es: item.es ? 'ON' : 'OFF',
            de: item.de ? 'ON' : 'OFF',
            updatedAt: moment(item.updated).fromNow(),
            createdAt: moment(item.created).format('DD MMMM YYYY')
        }
    })

    const options = {
        filter: false,
        filterType: 'dropdown',
        responsive: 'standard',
        serverSide: true,
        count: TOTAL,
        page: PAGE,
        rowsPerPage: LIMIT,
        rowsPerPageOptions: [],
        onTableChange: (action, tableState) => {
            console.log(action, tableState);

            const sortOrder = tableState.sortOrder || {};
            const sortFilter = sortOrder.name || '';
            const sortDirection = sortOrder.direction || '';


            switch (action) {

                case 'changePage':

                    onChangePageLegals({
                        page: tableState.page,
                        sort: onSortLegals(sortFilter),
                        direction: sortDirection
                    });

                    break;
                case 'sort':

                    onChangePageLegals({
                        page: tableState.page,
                        sort: onSortLegals(sortFilter),
                        direction: sortDirection
                    });

                    break;
                default:
                    console.log('action not handled.');
            }
        },
        searchText: searchText,
        searchProps: {
            onChange: (e) => {
                onSearchLegals(e.target.value);
            },
            onKeyUp: (e) => {
                if (e.keyCode === 13) {
                    onSearchLegals(e.target.value);
                }
            }
        },
        searchPlaceholder: 'Search Legal By Name',
        textLabels: {
            body: {
                noMatch: isLoading ?
                    'Searching Legals with the applied filters...' :
                    'Sorry, there is no matching data to display',
            },
        },



    };


    return (
        <MUIDataTable
            title={
                <Typography variant="h2" style={{ margin: '80px 20px' }}>

                    All Legals →

                    {isLoading ? (

                        <CircularProgress size={40} style={{ marginLeft: 15, position: 'relative', top: 4, color: '#26BFBF' }} />

                    ) : (

                        <>
                            {' '}<span style={{ color: '#26BFBF' }}>{CURRENT}</span>{'/' + TOTAL}
                        </>

                    )}

                </Typography>
            }
            data={data}
            columns={columns}
            options={options}
        />
    );

}


export default AdminLegals

